import React, { Component } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import PageTransition from 'gatsby-plugin-page-transitions';
import Navigation from "../components/Header/Navigation.js";
import PageBreak from "../components/repeating_elements/page_break.js";
import LazyLoad from "../components/repeating_elements/Lazy_Load.js";
import PanoramicHero from "../components/Global/Panoramic_Hero.js";
import JournalDisplay from "../components/Chatbox/Journal_Display.js";
import GetInTouchFooter from "../components/Global/Get_In_Touch_Footer.js";
import Footer from "../components/Footer/Footer.js";
import WorkWithUs from "../components/Global/Work_With_Us_Button.js";
import GDPR from '../components/Global/GDPR.js';
// import Loader from '../components/Global/Loader';
import SEO from '../components/seo.js';


class JournalPage extends Component {
	constructor(props){
		super(props);
		this.state = {
			isLoaded: this.props.pageContext.isLoaded,
			isLazyLoaded: this.props.pageContext.isLazyLoaded,
			isReady: this.props.pageContext.isReady,
			data: this.props.pageContext.data,
			cookieBanner: this.props.pageContext.cookieBanner,
			headerLinks: this.props.pageContext.header,
			footerLinks: this.props.pageContext.footer,
		}
	}
	componentDidMount() {

	}
	render() {
		let page_ready = this.state.isLoaded && this.state.isReady ? "page-ready page-loaded show-text" : "page-loading";
		if(this.state.isLoaded){

			return (
			<ParallaxProvider><PageTransition>
				<GDPR data={this.state.cookieBanner} />

				<SEO
					title={this.state.data[0].yoast_meta.yoast_wpseo_title}
					description={this.state.data[0].yoast_meta.yoast_wpseo_metadesc}
				/>
				<div className="site-contain">
					<Navigation links={this.state.headerLinks} />
					  <div className="page-main">
						<WorkWithUs />
						<div className={"page journal-page " + page_ready}>
							<PanoramicHero slides={this.state.data} />
							<LazyLoad>
								<JournalDisplay data={this.state.data[0].acf} />
							</LazyLoad>
							<PageBreak />
							<GetInTouchFooter data={this.state.data[0]} />
							<Footer links={this.state.footerLinks} />
						</div>
					</div>
				</div>
			</PageTransition></ParallaxProvider>
			)
		} else{

			return (
				<div></div>
			)
		}
	}
}

export default JournalPage
