import 'custom-event-polyfill'

export function create_work_close(){
	new CustomEvent('close-work-selectors', {
		bubbles: false
	});
}

export function dispatch_work_close(){
	window.dispatchEvent(new Event('close-work-selectors'));
}
