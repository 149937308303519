import React from "react";

import { Link } from 'gatsby';
import BackgroundImage from "../repeating_elements/Background-Image.js";
import LazyLoad from "../repeating_elements/Lazy_Load.js";
import { decode } from '../utilities/decode';

const Journal_Item = props => {
	return <Link to={props.url} className={"grid-m-6 grid-t-16 grid-d-1-3 journal-post" + props.projectClasses} title="Access Journal Item">
		<LazyLoad>
			<div className="image">
				<div className="bg-image">
					<BackgroundImage url={props.bgImg} loading="eager"/>
				</div>
			</div>
			<div className="title" dangerouslySetInnerHTML={{__html: decode(props.titleHtml)}}></div>
		</LazyLoad>
	</Link>;
}

export default Journal_Item;
