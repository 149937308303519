// const DOMParser = require('xmldom').DOMParser

export function decode(encodedStr) {
    let decodedStr = null
    const chars = [
        ['&#8217;', '\''],
        ['&amp;', '&'],
    ]
    for (let i = 0; i < chars.length; i++) {
        decodedStr = encodedStr.replace(chars[i][0], chars[i][1])
        if (decodedStr !== encodedStr) break
    }
    return decodedStr ? decodedStr : encodedStr
}