import React, { Component } from "react";


import {dispatch_work_close} from "../utilities/event-close-work-dropdown.js";

import ArrowHead from "../../assets/svg/arrow-head.svg";


export default class Drop_Down extends Component {
	constructor(props){
		super(props);
		let activeText = this.props.options.find(( i )=>{
			return this.props.active === i.industry_catagory.term_id;
		}) || this.props.default;
		this.state = {
			text: activeText.industry_catagory ? activeText.industry_catagory.name : this.props.default,
			active_element: this.props.active,
			open_status: false,
			interior_height: 0,
			class_names: " drop-down-closed"
		};
		this.link_list = React.createRef();
		this.toggle_module = this.toggle_module.bind(this);
		this.close_module = this.close_module.bind(this);
		this.open_module = this.open_module.bind(this);
		this.render_list_items = this.render_list_items.bind(this);
		this.handleChange = this.handleChange.bind(this);
		window.addEventListener('close-work-selectors', this.close_module);
	}
	toggle_module() {
		this.state.open_status ? this.close_module() : this.open_module();
	}
	close_module(){
		this.setState({
			open_status: false,
			interior_height: 0,
			class_names: " drop-down-closed"
		})
	}
	open_module(){
		dispatch_work_close()
		this.setState({
			open_status: true,
			interior_height: this.link_list.current.offsetHeight,
			class_names: " drop-down-opened"
		})
	}
	handleChange(e,t){
		this.close_module();
		this.setState({
			text: e,
			active_element: t
		})
		if (this.props.onChange) {
			this.props.onChange(t);
		}
	}
	render_list_items(){
		if(this.props.options){
			var items = this.props.options;
			return items.map((item) => {
				// var active = item.industry_catagory.term_id === this.state.active_element;
				return <li key={item.industry_catagory.name} className={item.industry_catagory.term_id === this.state.active_element ? "active" : ""}>
					<button aria-label={`Select ${item.industry_catagory.term_id} filter`} data-value={item.industry_catagory.term_id} onClick={()=>{this.handleChange(item.industry_catagory.name,item.industry_catagory.term_id)}}></button>
					<span dangerouslySetInnerHTML={{__html: item.industry_catagory.name}}></span>
				</li>
			});
		}
	}
	decode(encodedStr) {
		var parser = new DOMParser();
		var dom = parser.parseFromString(
			'<!doctype html><body>' + encodedStr,
			'text/html');
		var decodedString = dom.body.textContent;
		return decodedString
	}
	render() {
		return <div className={"drop-down"+this.state.class_names}>
					<div className="drop-down-title">
						<button aria-label="Filter by" onClick={this.toggle_module}></button>
						<span className="title">{this.decode(this.state.text)}</span>
						<span className="icon"><ArrowHead /></span>
					</div>
					<div className="drop-down-list-contain" style={{height: this.state.interior_height + "px"}}>
						<ul ref={this.link_list} className="drop-down-list">
							{ this.render_list_items() }
						</ul>
					</div>
				</div>;
	}
}
