export function get_url_params(){
	var urlParamsExist = window.location.href.split("?");
	var urlParams = {};
	if(urlParamsExist.length > 1){
		urlParamsExist[1].split("&").forEach(function(i){
			i = i.split("=");
			urlParams[i[0]+""] = i[1];
		})
	}
	return urlParams || false;
}
