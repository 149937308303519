import React, { Component } from "react";

// import Slider from "react-slick";
import { Link } from 'gatsby';
import { ArrowLink } from "../buttons/Arrow_Link.js";

import {get_url_params} from "../utilities/deep_link.js";
// import utility_data from "../utilities/browser_information.js";
import { request_page } from "../../components/data/fetch_data.js";

// import Background_Image from "../repeating_elements/Background-Image.js";
import LazyLoad from "../repeating_elements/Lazy_Load.js";
import PageBreak from "../repeating_elements/page_break.js";
import JournalItem from "../Chatbox/Journal_Item.js";
import DropDown from "../Chatbox/Drop_Down.js";

import { decode } from  "../utilities/decode.js";

export default class Journal_Display extends Component {
	constructor(props){
		super(props);
		this.in_view = false
		this.active_grid = "project news"
		var activeFilter = 34;
		var filterMap = {
			"birdwatch": 3,
			"breadcrumbs": 32,
			"culture": 33,
			"event-recap": 36,
			"project-news": 34
		};
		this.filterMap = filterMap
		this.load_more_post_amount = 9
		this.state = {
			urlParams: [],
			grid_shown: false,
			fade_class: "",
			work_posts: false,
			active_filter: activeFilter,
			intial_posts_loaded_amount: 8,
			pages_loaded: 0,
			loaded_posts: [],
			new_posts: [],
			load_more_interval: null,
			initial_load: true,
			filter_highlight_position: [0,0]
		};
		this.count = 0;
		this.show_timeout = null;
		this.request_page = request_page.bind(this);
		this.make_initial_grid = this.make_initial_grid.bind(this);
		this.make_filter = this.make_filter.bind(this);
		this.hide_grid = this.hide_grid.bind(this);
		this.show_grid = this.show_grid.bind(this);
		this.change_grid = this.change_grid.bind(this);
		this.filter_posts = this.filter_posts.bind(this);
		this.make_breadcrumb_feature = this.make_breadcrumb_feature.bind(this);
		this.load_more_grid = this.load_more_grid.bind(this);
		this.load_more = this.load_more.bind(this);
		this.load_more_ext = this.load_more_ext.bind(this);
	}
	componentDidMount(){
		this.request_page("journal_posts","posts?categories="+this.state.active_filter+"&per_page="+this.state.intial_posts_loaded_amount)
		this.request_page("breadcrumb_posts","posts?categories=32&per_page="+2)
		this.setState({urlParams: get_url_params()})
		if(this.state.cat){
			this.stateactiveFilter = this.filterMap[this.state.cat];
		}
	}
	load_more_ext(){
		var ext = document.getElementById("JS-load-more-exterior");
		var int = document.getElementById("JS-load-more-interior");
		setTimeout(function(){
			ext.style.height = int.clientHeight+"px";
		}, 1000)
	}
	load_more(){
		this.request_page("new_posts","posts?categories="+this.state.active_filter+"&per_page=" + this.load_more_post_amount + "&offset="+(this.state.intial_posts_loaded_amount + (this.load_more_post_amount * this.state.pages_loaded)), false, this.load_more_ext);
		this.setState({ load_more_interval: setInterval(() =>{
			if (this.state.new_posts) {
				if(this.state.new_posts.length > 0){
					let posts = this.state.new_posts;
					let loaded = this.state.pages_loaded;
					this.setState({
						loaded_posts: this.state.loaded_posts.concat(posts),
						pages_loaded: loaded+1,
						new_posts: []
					})
					clearInterval(this.state.load_more_interval)
				}
			}
		}, 300)})
	}
	make_initial_grid(){
		var project_list = this.state.journal_posts;
		var grid = [];
		var i = 0;
		var project_classes = "";
		if (project_list) {
			while(i < project_list.length){
				var filter_is_active = project_list[i].categories.find((y)=>{
					if(y === this.state.active_filter){
						return y
					}
					return null
				})
				if(!filter_is_active && this.state.filter){
					project_classes += " fade-project";
				}
				grid.push(
					<JournalItem
						key={project_list[i].slug}
						url={"/chatbox/"+project_list[i].slug}
						projectClasses={project_classes}
						bgImg={project_list[i].acf.thumbnail.sizes.medium}
						titleHtml={project_list[i].title.rendered}
					/>
				);
				i++;
			}
		}
		grid.push(
			<div className="grid-m-6 grid-t-16 grid-d-1-3 breadcrumb-feature">
				{this.make_breadcrumb_feature()}
			</div>
		);
		return(
			<div className="row work-display-grid">
				{ grid }
			</div>
		);
	}
	make_breadcrumb_feature(){
		// var project_list = this.state.journal_posts;
		var grid = [];
		if (this.state.breadcrumb_posts) {
			if(this.state.breadcrumb_posts.length > 0){
				this.state.breadcrumb_posts.forEach(function(i,x){
					grid.push(
						<Link key={i.slug} to={"/chatbox/"+i.slug} className="breadcrumb-post" title="See Breadcrumbs post">
							<p>{decode(i.title.rendered)}</p>
						</Link>
					)
				});
			}
		}
		grid.push(<ArrowLink title="Subscribe to #Breadcrumbs" url="http://thecharlesnyc.us4.list-manage.com/subscribe?u=5b76d600d742826acd5b885bd&id=4baeed4900" />)
		return <LazyLoad>{grid}</LazyLoad>;
	}
	load_more_grid(){
		var project_list = this.state.loaded_posts;
		var grid = [];
		var i = 0;
		var project_classes = " ";
		let load_more_class = " ";
		if (project_list) {
			while(i < project_list.length){
				grid.push(
					<JournalItem
						key={project_list[i].slug}
						url={"/chatbox/"+project_list[i].slug}
						projectClasses={project_classes}
						bgImg={project_list[i].acf.thumbnail.sizes.medium}
						titleHtml={project_list[i].title.rendered}
					/>
				);
				i++;
			}
		}
		setTimeout(()=>{
			load_more_class = "";
		}, 1000)
		return(
			<div id="JS-load-more-exterior" className={ "row load-more-grid" + load_more_class }>
				<div id="JS-load-more-interior" className="row load-more-interior work-display-grid">
					{ grid }
				</div>
			</div>
		);
	}
	filter_posts(value, element){
		var ext = document.getElementById("JS-load-more-exterior");
		var active_position = false;
		var active_position_calc = 0;
			ext.style.height = "0px";

		if(element){
			active_position = document.getElementById(element.key).getBoundingClientRect();
			active_position_calc = [active_position.left - this.chatbox.offsetLeft,active_position.width];
		}
		this.setState({
			active_filter: value,
			intial_posts_loaded_amount: 8,
			loaded_posts: [],
			pages_loaded: 0,
			new_posts: [],
			filter_highlight_position: active_position_calc
		});
		this.change_grid()
	}
	make_filter(){
		var parent = this;
		var filters = this.props.data.industries_filter;
		var filter_elements = [];
		// function add_all_post(){
		// 	var element = <li id="post-filter-all" key="post-filter-all" className={ 31 === parent.state.active_filter ? "active" : "" }>
		// 		<button value="31" onClick={()=>{parent.filter_posts(31, element)}}></button>
		// 		All
		// 	</li>;
		// 	filter_elements.push(element);
		// }
		if(filters.length > 1){
			filters.forEach(function(filter){
				var element = <li id={"post-filter-"+filter.industry_catagory.name} key={"post-filter-"+filter.industry_catagory.name} className={ filter.industry_catagory.term_id === parent.state.active_filter ? "active" : "" }>
					<button value={filter.industry_catagory.term_id} onClick={()=>{parent.filter_posts(filter.industry_catagory.term_id, element)}}></button>
					{filter.industry_catagory.name}
				</li>;
				filter_elements.push(element)
			})
			return filter_elements;
		}
	}
	hide_grid(){
		clearTimeout(this.show_timeout)
		this.setState({
			grid_shown: false,
			fade_class: ""
		})
	}
	show_grid(){
		this.setState({
			grid_shown: true,
			fade_class: " grid-loaded"
		})
	}
	change_grid(){
		this.hide_grid();
		clearTimeout(this.show_timeout);
		setTimeout(()=>{
			this.request_page("journal_posts","posts?categories="+this.state.active_filter+"&per_page="+this.state.intial_posts_loaded_amount)
		},400)
	}
	oldposts = null
	render() {
		const in_view = this.in_view ? "" : "";
		if(this.state.journal_posts){
			var highlight_styles = {
				left: ( this.state.filter_highlight_position[0] === 0 ? 20 : this.state.filter_highlight_position[0] - 60 || 20 )+"px" ,
				width: ( this.state.filter_highlight_position[1] || 101 )+"px"
			};
			if(!this.state.grid_shown){
				this.show_timeout = setTimeout(()=>{
					const checkNewPosts = () => {
						if (this.state.journal_posts !== this.oldposts) {
							this.oldposts = this.state.journal_posts
							this.show_grid()
						}
						else if (!this.state.grid_shown) setTimeout(checkNewPosts, 100)
					}
					checkNewPosts()
				}, 1000);
			}
			return <div className={"journal-list grid"} ref={c => this.chatbox = c}>
				<div className="row options project-filter-container">
					<div className="grid-m-hide grid-t-16 grid-d-16">
						<ul className="project-filter">
							{ this.make_filter() }
						</ul>
						<div className="highlight" style={highlight_styles}></div>
					</div>
					<div className="grid-m-6 grid-t-hide grid-d-hide journal-dropdown-contain">
						<DropDown default="Project News" options={this.props.data.industries_filter} active={this.active_grid} onChange={this.filter_posts} />
					</div>
					<div className="page-break grid-m-hide grid-t-16 grid-d-16"></div>
				</div>
				<div className={"grid-contain row"+this.state.fade_class}>
					{this.make_initial_grid()}
					{this.load_more_grid()}
				</div>
				<div className="grid-m-6 grid-t-16 grid-d-16 load-more-container">
					<button className="button" onClick={this.load_more}>Load More</button>
				</div>
			</div>;
		}
		return <div className={"work-display grid" + in_view}>
			<div className="grid-m-6 grid-t-8 grid-d-8">
			</div>
			<PageBreak />
			<div className={"grid-contain row"}>
				<div className="row work-display-grid">
					<div className="project-column grid-m-6">
						<div className="project-item tall">
							<div className="item-imagery">
								{/* <div className="frame"></div> */}
								<div className="fill"></div>
								<div className="thumbnail"></div>
							</div>
						</div>
					</div>
					<div className="project-column grid-m-6">
						<div className="project-item tall">
							<div className="item-imagery">
								{/* <div className="frame"></div> */}
								<div className="fill"></div>
								<div className="thumbnail"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="load-more-container grid-m-6 grid-t-hide grid-d-hide">
				<span className="button">Load More</span>
			</div>
		</div>;
	}
}
